import { required, maxLength, alpha} from "vuelidate/lib/validators";
import { onlyText, nameLength } from '@/_validations/validacionEspeciales';

export default () => {
    return {
        CustomOperationDsEs: { required, onlyText, nameLength,  maxLength: maxLength(50) },
        CustomOperationDsEn: { required, onlyText, nameLength, maxLength: maxLength(50) },
        CustomOperationAbbrev: { required, onlyText, maxLength: maxLength(8) },
        CustomOperationColor: { required},
    }
}

