<template>
  <form @submit.prevent="submit">
    <loading-overlay
      :active="Loading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      @update:show="$emit('close');"
    >
      <CRow>
          <CCol sm="12" lg="12">
            <CCardBody>
              <CRow>
                <CCol sm="12" lg="12">
                  <CInput
                    :label="`${$t('label.name')} (ES)`"
                    addLabelClasses="required text-right"
                    v-uppercase
                    :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                    :placeholder="`${$t('label.customsRegimeName')} (ES)`"
                    v-model="$v.CustomOperationDsEs.$model"
                    :is-valid="hasError($v.CustomOperationDsEs)"
                    :invalid-feedback="errorMessage($v.CustomOperationDsEs)"
                  >
                  </CInput>    
                </CCol>
                <CCol sm="12" lg="12">
                  <CInput
                    :label="`${$t('label.name')} (EN)`"
                    addLabelClasses="required text-right"
                    v-uppercase
                    :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                    :placeholder="`${$t('label.customsRegimeName')} (En)`"
                    v-model="$v.CustomOperationDsEn.$model"
                    :is-valid="hasError($v.CustomOperationDsEn)"
                    :invalid-feedback="errorMessage($v.CustomOperationDsEn)"
                  >
                  </CInput>    
                </CCol>
                <CCol sm="12" lg="12">
                  <CInput
                    :label="$t('label.abbreviation')"
                    addLabelClasses="required text-right"
                    v-uppercase
                    :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                    :placeholder="$t('label.abbreviation')"
                    v-model="$v.CustomOperationAbbrev.$model"
                    :is-valid="hasError($v.CustomOperationAbbrev)"
                    :invalid-feedback="errorMessage($v.CustomOperationAbbrev)"
                  >
                  </CInput>    
                </CCol>
                <CCol sm="12" lg="12">
                  <CInput
                      type="color"
                      :label="$t('label.color')"
                      addLabelClasses="required text-right"
                      :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                      v-model="$v.CustomOperationColor.$model"
                      :is-valid="hasError($v.CustomOperationColor)"
                      :invalid-feedback="errorMessage($v.CustomOperationColor)"
                  />
                </CCol>
                <CCol sm="12" lg="12" v-if="edit">
                  <CSelect
                    :options="statusOptions"
                    :value.sync="Status"
                    :label="$t('label.status')"
                    :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                    addLabelClasses="required text-right"
                    :is-valid="statusSelectColor"
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCol>
      </CRow>
      <template #footer>
          <CButton
          square
          color="add"
          class="d-flex align-items-center"
          :disabled="isSubmit"
          @click.stop="edit ? statusConfirmation(CustomsRegimeItems.FgActCustomOperation, Status, submit) : submit()"
          >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
          </CButton>
          <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="$emit('close');"
          >
          <CIcon name="x" /><span class="ml-1">{{$t('button.cancel')}}</span>
          </CButton>
      </template>
    </CModalExtended>
  </form>
</template>

<script>
import ModalMixin from '@/_mixins/modal';
import UpperCase from '@/_validations/uppercase-directive';
import customsRegimeValidations from '@/_validations/customs-Regime/customsRegimeVal';

function data() {
return {
  isSubmit: false,
  modalActive: false,
  Loading: false,
  CustomOperationId: '',
  CustomOperationDsEs: '',
  CustomOperationDsEn: '',
  CustomOperationAbbrev: '',
  CustomOperationColor: '',
  Status: 0
};
}

function submit() {
try {
  this.isSubmit = true;
  this.Loading = true;
  this.$v.$touch();
  if (this.$v.$error) {
  this.isSubmit = false;
  this.Loading = false;
  throw this.$t('label.errorsPleaseCheck');
  }

  let CustomOperationJson = this.edit ? {
      CustomOperationId: this.CustomOperationId,
      CustomOperationDsEs: this.CustomOperationDsEs,
      CustomOperationDsEn: this.CustomOperationDsEn,
      CustomOperationAbbrev: this.CustomOperationAbbrev,
      CustomOperationColor: this.CustomOperationColor,
      Status: this.Status
  }:{
      CustomOperationDsEs: this.CustomOperationDsEs,
      CustomOperationDsEn: this.CustomOperationDsEn,
      CustomOperationAbbrev: this.CustomOperationAbbrev,
      CustomOperationColor: this.CustomOperationColor,
  };

  let metodo = this.edit ? 'PUT':'POST';
  let ruta = this.edit ? 'CustomOperation-update': 'CustomOperation-insert';

  this.$http.ejecutar(metodo, ruta, CustomOperationJson, { root: 'CustomOperationJson' })
  .then(response => {
      this.$emit('submited');
      this.modalActive = false;
      this.$notify({
      group: 'container',
      title: '¡Exito!',
      text: response.data.data[0].Response,
      type: "success"
      });
      this.resetForm();
  }).catch(err => {
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
      });
  }).then(() => {
      this.isSubmit = false;
      this.Loading = false;
  });
} catch (error) {
  this.$notify({
  group: 'container',
  title: '¡Error!',
  text: error,
  type: "error"
  });
}

}

function statusSelectColor() {
  return this.Status === 1;
}

function getData(val) {
  this.CustomOperationId = val.CustomOperationId;
  this.CustomOperationDsEs = val.CustomOperationDsEs;
  this.CustomOperationDsEn = val.CustomOperationDsEn;
  this.CustomOperationAbbrev = val.CustomOperationAbbrev;
  this.CustomOperationColor = val.CustomOperationColor;
  this.Status = val.FgActCustomOperation ?1:0;
  this.$v.$touch();
}
function resetForm() {
  this.CustomOperationId = '';
  this.CustomOperationDsEs = '';
  this.CustomOperationDsEn = '';
  this.CustomOperationAbbrev = '';
  this.CustomOperationColor = '';
  this.Status = 0;
  this.$v.$reset();
}


export default {
name: 'CustomsRegime-modal',
props: { modal: Boolean, edit: Boolean,title: String, CustomsRegimeItems: Object},
data,
validations: customsRegimeValidations,
mixins: [
  ModalMixin,
],
directives: UpperCase,
methods: {
  statusSelectColor,
  getData,
  submit,
  resetForm,
},
computed: {

},
watch: {
  modal: function (val) {
    this.modalActive = val;
    if(val){
      if (this.edit) {
        this.getData(this.CustomsRegimeItems);
      }
    }else{
      this.resetForm();
    }
  },
},

};
</script>
